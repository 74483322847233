<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="space-between"
              ><h4>Pedidos Entregues e Finalizados</h4></el-row
            >
          </el-col>
          <el-dropdown
            @command="(c) => (displayPage = c)"
            size="medium"
            trigger="click"
          >
            <el-button type="primary" size="medium">
              Estado do Pedido: {{ DisplayPage || ""
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="option in PageOptions"
                  :key="option"
                  :command="option.value"
                >
                  <li
                    :class="{
                      'el-dropdown-menu__item': true,
                      'no-padding': true,
                      active: displayPage === option.value,
                    }"
                  >
                    {{ option.label }}
                  </li>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-row>
      </template>
      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        :data="Orders"
        style="width: 100%; z-index: 0"
        :row-style="() => 'cursor:pointer;'"
        @row-click="(r) => openOrderModal(r)"
      >
        <el-table-column label="#" prop="code" width="100"></el-table-column>
        <el-table-column
          label="romaneio"
          prop="invoice_id"
          width="100"
        ></el-table-column>
        <el-table-column prop="seller.name" label="vendedor"> </el-table-column>
        <el-table-column prop="client.name" label="cliente"></el-table-column>
        <el-table-column prop="client.name" label="cliente">
          <template #default="{ row: order }">
            {{ order.client?.legal?.social_name || order.client?.name }}
          </template>
        </el-table-column>
        <el-table-column
          label="data pedido"
          width="100"
          :formatter="
            (r) => dateFormatter.format(new Date(r.created_at || Date.now()))
          "
        >
        </el-table-column>
        <el-table-column
          label="data entrega"
          width="100"
          :formatter="
            (r) => dateFormatter.format(new Date(r.deliver_at) || Date.now())
          "
        >
        </el-table-column>
        <el-table-column label="nota" prop="invoice.nfe"></el-table-column>
        <el-table-column
          label="total"
          :formatter="
            (order) => currencyFormatter.format(Number(order.total_amount))
          "
        ></el-table-column>
        <el-table-column label="estado" prop="status_formatted" width="170">
          <template #default="s">
            <el-tag :type="getStatusType(s.row)">
              {{ s.row.status_formatted }}</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @update:current-page="currentPage = $event"
        :current-page="currentPage"
        background
        layout="prev, pager, next"
        :page-count="orders?.lastPage || 1"
      >
      </el-pagination>

      <order-modal
        :order="order"
        :showModal="showOrderModal"
        @should-update="fetchOrders"
        @close-modal="showOrderModal = false"
      ></order-modal>
    </el-card>
  </div>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderModal from "./modals/OrderModal.vue";
import OrderService from "../services/orders";
import { dateFormatter, currencyFormatter } from "../utils/formatters";
import { hasOnlyDigits } from "../utils/functions";
export default {
  name: "OrdersCompletedPage",
  components: { OrderModal },
  data: () => ({
    hasError: false,
    order: {},
    showOrderModal: false,
    currentPage: 1,
    isLoading: true,
    orders: null,
    displayPage: "completed",
    currencyFormatter,
    dateFormatter,
    fetchRepeater: null,
  }),
  mounted() {
    this.fetchOrders();
    this.$store.commit("update-search", "");
    this.fetchRepeater = setInterval(() => {
      this.fetchOrders(false);
    }, 5000);
  },
  unmounted() {
    clearInterval(this.fetchRepeater);
  },
  watch: {
    currentPage() {
      this.fetchOrders();
    },
    displayPage() {
      let shouldFetchOrders = this.currentPage === 1;
      this.currentPage = 1;

      if (shouldFetchOrders) this.fetchOrders();
    },
    SearchName() {
      this.displayPage = "all";
      this.currentPage = 1;
      this.fetchOrders();
    },
  },
  computed: {
    SearchName() {
      return this.$store.state.searchNameQuery;
    },
    PageOptions() {
      return [
        {
          label: "Tudo",
          value: "all",
        },
        {
          label: "Estornados",
          value: "canceled",
        },
        {
          label: "Aguardando pagamento",
          value: "completed",
        },
        {
          label: "Pago",
          value: "paid",
        },
        {
          label: "Finalizados",
          value: "finalized",
        },
      ];
    },
    DisplayPage() {
      const selectedOption = this.PageOptions.find(
        (o) => o.value === this.displayPage
      );

      return selectedOption?.label || "Tudo";
    },
    Orders() {
      return this.orders?.data || [];
    },
    OrderStats() {
      if (this.Orders?.length) {
        const delayed = this.Orders.filter((o) => this.isDelayed(o))?.length;
        const on_time = this.Orders.length - delayed;
        const producing = this.Orders.filter(
          (o) => o.status === "production"
        )?.length;
        const last_day = this.Orders.filter(
          (o) => !this.isDelayed(o) && this.hoursLeft(o.deliver_at) > 18
        )?.length;

        return {
          delayed,
          on_time,
          producing,
          last_day,
          total: this.Orders.length,
        };
      }

      return null;
    },
  },
  methods: {
    formatCurrency(c) {
      return this.currencyFormatter.format(c || 0);
    },
    hoursLeft(time) {
      return (new Date(time) - new Date()) / 36e5;
    },
    openOrderModal(order) {
      this.order = order;
      this.showOrderModal = true;
    },
    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production"].includes(order.status)
      )
        order.status = "delayed";
      return ORDER_STATUS_TYPES[order.status];
    },
    async fetchOrders(showLoading = true) {
      if (showLoading) this.isLoading = true;
      let orderStatus;
      if (
        { canceled: true, finalized: true, paid: true, completed: true }[
          this.displayPage
        ]
      )
        orderStatus = this.displayPage;
      else orderStatus = "completed,finalized,canceled,paid";

      const { orders } = await OrderService().index({
        status: orderStatus,
        deleted_at: new Date(),
        searchName: (!hasOnlyDigits(this.SearchName) && this.SearchName) || "",
        searchCpfCnpj:
          (hasOnlyDigits(this.SearchName) && this.SearchName) || "",
        pagination: this.currentPage,
        limit: 15
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  paid: "success",
  delayed: "danger",
  canceled: "danger",
  delivered: "success",
  completed: "info",
  finalized: "success",
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>
